import { getShopifyLanguageFromCurrentURL, isKeyOf } from '@ifixit/helpers';
import { AnalyticsItem, AnalyticsItemsEvent } from '..';
import { piwikPush } from './piwikPush';

export { trackInPiwik } from './track-event';

type PiwikCustomDimensions = {
   preferredStore: number;
   preferredLanguage: number;
   userPrivilege: number;
   visitedStore: number;
   oemPartnership: number;
   pageBreadcrumbs: number;
   featuredProducts: number;
};

export function trackPiwikPreferredStore(piwikEnv: string | undefined, storeDomain: string): void {
   const customDimensions = getPiwikCustomDimensionsForEnv(piwikEnv);
   if (typeof window !== 'undefined' && customDimensions) {
      piwikPush(['setCustomDimensionValue', customDimensions['preferredStore'], storeDomain]);
   }
}

export function trackPiwikPreferredLanguage(
   piwikEnv: string | undefined,
   preferredLang: string | null
): void {
   const customDimensions = getPiwikCustomDimensionsForEnv(piwikEnv);
   if (typeof window !== 'undefined' && customDimensions) {
      const lang = preferredLang?.toUpperCase() ?? getShopifyLanguageFromCurrentURL();
      piwikPush(['setCustomDimensionValue', customDimensions['preferredLanguage'], lang]);
   }
}

export function trackPiwikUserPrivilege(
   piwikEnv: string | undefined,
   userPrivilege: string | null
): void {
   const customDimensions = getPiwikCustomDimensionsForEnv(piwikEnv);
   if (typeof window !== 'undefined' && customDimensions && userPrivilege) {
      piwikPush(['setCustomDimensionValue', customDimensions['userPrivilege'], userPrivilege]);
   }
}

export function trackVisitedStore(piwikEnv: string | undefined): void {
   const customDimensions = getPiwikCustomDimensionsForEnv(piwikEnv);
   if (typeof window !== 'undefined' && customDimensions) {
      piwikPush(['setCustomDimensionValue', customDimensions['visitedStore'], true]);
   }
}

export function trackOEMPartnershipEvent(
   piwikEnv: string | undefined,
   oemPartnership: string | undefined
): void {
   const customDimensions = getPiwikCustomDimensionsForEnv(piwikEnv);
   if (typeof window !== 'undefined' && customDimensions) {
      piwikPush(['setCustomDimensionValue', customDimensions['oemPartnership'], oemPartnership]);
   }
}

export function trackPiwikFeaturedProducts(
   piwikEnv: string | undefined,
   productSKUs: string[]
): void {
   const customDimensions = getPiwikCustomDimensionsForEnv(piwikEnv);
   if (typeof window !== 'undefined' && customDimensions) {
      piwikPush(['setCustomDimensionValue', customDimensions['featuredProducts'], productSKUs]);
   }
}

export function trackPageBreadcrumbEvent(
   piwikEnv: string | undefined,
   breadcrumbs: {
      label: string;
      url?: string;
   }[]
): void {
   const customDimensions = getPiwikCustomDimensionsForEnv(piwikEnv);
   if (typeof window !== 'undefined' && customDimensions && breadcrumbs.length >= 2) {
      if (
         !breadcrumbs[breadcrumbs.length - 1]?.url ||
         breadcrumbs[breadcrumbs.length - 1]?.url === '#'
      ) {
         breadcrumbs[breadcrumbs.length - 1].url = window.location.pathname;
      }
      const pageBreadcrumbs =
         ':' +
         breadcrumbs
            .map(breadcrumb => decodeURIComponent(breadcrumb.url?.split('/').pop() ?? ''))
            .join(':') +
         ':';
      piwikPush(['setCustomDimensionValue', customDimensions['pageBreadcrumbs'], pageBreadcrumbs]);
   }
}

export function trackPiwikV2ProductDetailView(event: AnalyticsItemsEvent) {
   piwikPush([
      'ecommerceProductDetailView',
      event.items.map(item => formatProduct(item, event.currency)),
   ]);
}

export function trackPiwikV2AddToCart(event: AnalyticsItemsEvent) {
   piwikPush(['ecommerceAddToCart', event.items.map(item => formatProduct(item, event.currency))]);
}

export function trackPiwikV2RemoveFromCart(event: AnalyticsItemsEvent) {
   piwikPush([
      'ecommerceRemoveFromCart',
      event.items.map(item => formatProduct(item, event.currency)),
   ]);
}

export function trackPiwikCartUpdate(event: AnalyticsItemsEvent) {
   piwikPush([
      'ecommerceCartUpdate',
      event.items.map(item => formatProduct(item, event.currency)),
      event.value.toString(),
   ]);
}

export function clearPiwikCustomDimensions(piwikEnv: string | undefined, dimensions: string[]) {
   const customDimensions = getPiwikCustomDimensionsForEnv(piwikEnv);
   if (customDimensions) {
      dimensions.forEach(element => {
         if (isKeyOf(element, customDimensions)) {
            piwikPush(['deleteCustomDimension', customDimensions[element]]);
         }
      });
   }
}

export function setPiwikPageTitle() {
   if (typeof document !== 'undefined') {
      piwikPush(['setDocumentTitle', document.title]);
   }
}

function getPiwikCustomDimensionsForEnv(env: string | undefined): PiwikCustomDimensions | null {
   switch (env) {
      case 'prod': {
         return {
            preferredStore: 1,
            preferredLanguage: 2,
            userPrivilege: 3,
            visitedStore: 6,
            oemPartnership: 8,
            pageBreadcrumbs: 10,
            featuredProducts: 11,
         };
      }
      case 'dev': {
         return {
            preferredStore: 1,
            preferredLanguage: 2,
            userPrivilege: 3,
            visitedStore: 4,
            oemPartnership: 6,
            pageBreadcrumbs: 9,
            featuredProducts: 10,
         };
      }
      default: {
         return null;
      }
   }
}

function formatProduct(item: AnalyticsItem, currencyCode: string) {
   const currency = isKeyOf(currencyCode, currencyConversions) ? currencyCode : 'USD';
   return {
      sku: item.item_id,
      name: item.item_name,
      price: toUSD(item.price, currency).toString(),
      quantity: item.quantity,
      variant: item.item_variant,
      category: item.categories,
      customDimensions: {
         '1': item.fulfiller,
      },
   };
}

const currencyConversions = {
   AUD: 0.75,
   CAD: 0.815,
   EUR: 1.15,
   GBP: 1.3,
   USD: 1,
};

export type CurrencyCode = keyof typeof currencyConversions;

export function toUSD(price: number, currencyCode: CurrencyCode) {
   const currencyConversionRatio = currencyConversions[currencyCode] ?? 1;
   return price * currencyConversionRatio;
}
