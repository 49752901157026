import { createSharedState } from 'Shared/use-shared-state';

export const selectedWikiDeviceSlice = createSharedState<null | string>(
   'wikiDevice',
   App.deviceTitle
);

export const savingState = createSharedState<boolean>('saving', false);

export const inputFormValue = createSharedState<string>('inputValue', App.inputFormValue);
