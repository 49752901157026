import Cookies from 'js-cookie';

export function hasUserConsented(piwikSiteId: string) {
   const cookie = Cookies.get(`ppms_privacy_${piwikSiteId}`);
   if (!cookie) {
      return null;
   }

   let cookieValue: { consents?: { [name: string]: { status: number } } };
   try {
      cookieValue = JSON.parse(cookie);
   } catch {
      return null;
   }

   if (!cookieValue.consents) {
      return null;
   }
   return Object.values(cookieValue.consents).every(consent => consent.status === 1);
}
