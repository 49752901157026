import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import API_2_0 from 'Shared/api_2_0';

export enum TeamMembersActionType {
   SET_TEAM_MEMBERS = 'SET_TEAM_MEMBERS',
   TEAM_MEMBERS_ERROR = 'TEAM_MEMBERS_ERROR',
}

export const getTeamMembers =
   (teamid: number): ThunkAction<any, any, any, Action> =>
   dispatch => {
      const request = new API_2_0(`course_assignments/teamSummaries/${teamid}`, {
         method: 'GET',
      });
      return request.send().then(
         (body: any) => {
            dispatch(setTeamMembers(teamid, body.members, body.courses));
         },
         (error: any) => {
            dispatch(teamMembersError(teamid, error));
         }
      );
   };

export interface SetTeamMembersAction extends Action {
   type: typeof TeamMembersActionType.SET_TEAM_MEMBERS;
   teamid: number;
   members: any[];
   courses: any[];
}

const setTeamMembers = (teamid: number, members: any[], courses: any[]): SetTeamMembersAction => ({
   type: TeamMembersActionType.SET_TEAM_MEMBERS,
   teamid,
   members,
   courses,
});

export interface TeamMembersErrorAction extends Action {
   type: typeof TeamMembersActionType.TEAM_MEMBERS_ERROR;
   teamid: number;
   error: any;
}

const teamMembersError = (teamid: number, error: any): TeamMembersErrorAction => ({
   type: TeamMembersActionType.TEAM_MEMBERS_ERROR,
   teamid,
   error,
});
