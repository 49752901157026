import { useEffect } from 'react';
import { hasUserConsented } from './consent';
import { ShopifyConfig } from './types';

type ShopifyConsentProps = {
   piwikSiteId: string;
   shopifyConfig: ShopifyConfig;
   needsConsent: boolean;
};

export const useShopifyConsentScript = ({
   piwikSiteId,
   shopifyConfig,
   needsConsent,
}: ShopifyConsentProps) => {
   useEffect(() => {
      const script = document.createElement('script');
      script.src =
         'https://cdn.shopify.com/shopifycloud/consent-tracking-api/v0.1/consent-tracking-api.js?shpxid=c0d65ea6-DB5F-41EB-BE8D-E48D42A4B775';
      script.async = true;
      script.onload = () => {
         const userConsent = hasUserConsented(piwikSiteId) ?? !needsConsent;
         if (shopifyConsentMatches(userConsent)) {
            /* eslint-disable-next-line no-console */
            console.log('Shopify consent already matches:', userConsent);
         } else {
            /* eslint-disable-next-line no-console */
            console.log('Sending consent to Shopify:', userConsent);
            window.Shopify?.customerPrivacy.setTrackingConsent({
               'marketing': userConsent,
               'analytics': userConsent,
               'preferences': userConsent,
               'sale_of_data': userConsent,
               'headlessStorefront': true,
               'checkoutRootDomain': shopifyConfig.checkout,
               'storefrontRootDomain': shopifyConfig.storefront,
               'storefrontAccessToken': shopifyConfig.accessToken,
            });
         }
      };

      document.body.append(script);

      return () => {
         script.remove();
      };
   }, [piwikSiteId, shopifyConfig, needsConsent]);
};

function shopifyConsentMatches(userConsent: boolean) {
   const currentVisitorConsent = window.Shopify?.customerPrivacy.currentVisitorConsent();
   if (!currentVisitorConsent) {
      return false;
   }
   const consentChoice = userConsent ? 'yes' : 'no';
   const { analytics, marketing, preferences, sale_of_data } = currentVisitorConsent;
   return (
      analytics === consentChoice &&
      marketing === consentChoice &&
      preferences === consentChoice &&
      sale_of_data === consentChoice
   );
}
