import { getShopifyLanguageFromCurrentURL } from '@ifixit/helpers';
import { SentryError } from '@ifixit/sentry';
import debounce from 'lodash/debounce';
import { AnalyticsItem, AnalyticsItemsEvent } from '..';

type GrowthbookEvent = {
   eventName: string;
   experimentId: string;
   variationId: string;
};

type GTagViewItemsListEvent = {
   items: AnalyticsItem[];
   item_list_id: string;
   item_list_name: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GTagArg = Array<any>;

export type GTag = (...args: GTagArg) => void;
declare global {
   interface Window {
      gtag?: GTag;
      dataLayer?: GTagArg;
   }
}

let DEBUG_MODE = false;

if (typeof window !== 'undefined') {
   DEBUG_MODE = window.location.search.includes('ga4_debug=true');

   window.dataLayer = window.dataLayer || [];
   window.gtag = windowGtag;
   window.gtag('js', new Date());
}

function gtag(...args: GTagArg) {
   if (typeof window === 'undefined') {
      return;
   }

   if (!window.gtag) {
      throw new SentryError('window.gtag is not defined');
   }

   if (DEBUG_MODE) {
      // eslint-disable-next-line no-console
      console.log('[GA4] gtag', ...args);
   }

   window.gtag(...args);
}

function windowGtag() {
   // eslint-disable-next-line prefer-rest-params
   window?.dataLayer?.push(arguments);
}

export function initGA4ArrayOnWindow({
   GtagID,
   debugMode,
}: { GtagID: string | undefined; debugMode: boolean }) {
   if (typeof window === 'undefined' || !GtagID || !window.gtag) {
      return;
   }

   if (debugMode) {
      DEBUG_MODE = true;
   }

   gtag('config', GtagID, DEBUG_MODE ? { debug_mode: true } : {});
}

export function setGA4Consent({ consent, update }: { consent: boolean; update?: boolean }) {
   const type = update ? 'update' : 'default';
   const consentValue = consent ? 'granted' : 'denied';
   gtag('consent', type, {
      ad_storage: consentValue,
      ad_user_data: consentValue,
      ad_personalization: consentValue,
      analytics_storage: consentValue,
   });
}

export function setUserDimensions(dimensions: GACustomDimensions) {
   gtag('set', 'user_properties', dimensions);
}

export function trackGA4ViewItem(event: AnalyticsItemsEvent) {
   gtag('event', 'view_item', formatEvent(event));
}

export function trackGA4ViewCart(event: AnalyticsItemsEvent) {
   gtag('event', 'view_cart', formatEvent(event));
}

export function trackGA4AddToCart(event: AnalyticsItemsEvent) {
   gtag('event', 'add_to_cart', formatEvent(event));
}

export function trackGA4RemoveFromCart(event: AnalyticsItemsEvent) {
   gtag('event', 'remove_from_cart', formatEvent(event));
}

export function trackGA4ViewItemList(event: GTagViewItemsListEvent) {
   gtag('event', 'view_item_list', event);
}

export const debouncedTrackGA4ViewItemList: GTag = debounce((event: GTagViewItemsListEvent) => {
   trackGA4ViewItemList(event);
}, 500);

type GACustomDimensions = {
   preferred_store: string;
   preferred_language: string;
};

export function getGACustomDimensions(
   preferredLangid: string | undefined | null,
   preferredStore: string
): GACustomDimensions {
   return {
      preferred_store: preferredStore,
      preferred_language:
         preferredLangid?.toUpperCase() ||
         getShopifyLanguageFromCurrentURL() ||
         'no-language-found',
   };
}

function formatEvent(event: AnalyticsItemsEvent) {
   const { items, ...rest } = event;
   const formatedItems = items.map(item => {
      const { categories, fulfiller, ...rest } = item;
      return {
         ...rest,
      };
   });
   return {
      ...rest,
      items: formatedItems,
   };
}

export const trackGA4GrowthbookEvent = (trackData: GrowthbookEvent) => {
   gtag('event', trackData.eventName, {
      'experiment_id': trackData.experimentId,
      'variation_id': trackData.variationId,
   });
};

export function trackCustomGAEvent(eventName: string, eventParams: Record<string, unknown>) {
   gtag('event', eventName, eventParams);
}
