import { getVariantIdFromVariantURI } from '@ifixit/helpers';
import type { CartLineItem } from '@ifixit/shopify-cart-sdk';
import {
   trackInPiwik,
   trackPiwikV2AddToCart,
   trackPiwikV2ProductDetailView,
   trackPiwikV2RemoveFromCart,
} from './piwik';
import {
   trackCustomGAEvent,
   trackGA4AddToCart,
   trackGA4RemoveFromCart,
   trackGA4ViewItem,
} from './google';

export * from './piwik';
export * from './google';

export type AnalyticsItem = {
   item_id: string | null | undefined;
   item_name: string | null;
   item_variant: string | null;
   quantity: number | null | undefined;
   price: number;
   categories?: string[];
   fulfiller?: string;
};

export type AnalyticsItemsEvent = {
   items: AnalyticsItem[];
   value: number;
   currency: string;
};

export function trackInAnalyticsViewItem(event: AnalyticsItemsEvent) {
   trackGA4ViewItem(event);
   trackPiwikV2ProductDetailView(event);
}

export function trackInAnalyticsAddToCart(event: AnalyticsItemsEvent) {
   trackGA4AddToCart(event);
   trackPiwikV2AddToCart(event);
}

export function trackInAnalyticsRemoveFromCart(event: AnalyticsItemsEvent) {
   trackGA4RemoveFromCart(event);
   trackPiwikV2RemoveFromCart(event);
}

export function convertCartLineItemsToAnalyticsItem(items: CartLineItem[]): AnalyticsItem[] {
   return items.map(item => ({
      item_id: item.itemcode,
      item_name: `${item.name} - ${item.variantTitle}`,
      item_variant: getVariantIdFromVariantURI(item.shopifyVariantId),
      quantity: item.quantity,
      price: Number(item.price.amount),
      categories: item.categories ?? [],
      fulfiller: item.fulfiller,
   }));
}

export function getCategoriesFromAPICategories(apiCategories: {
   is_tool: boolean;
   item_category: string;
   part_subcategory: string;
}): string[] {
   const firstCategory = apiCategories.is_tool ? 'Tool' : 'Part';
   return [firstCategory, apiCategories.item_category, apiCategories.part_subcategory];
}

export type GenericTrackData = Parameters<typeof trackCustomGAEvent>[1] & {
   eventCategory: string;
   eventAction: string;
   eventName: string;
   eventValue?: number;
};

export function trackCustomEvent(props: GenericTrackData) {
   const { eventCategory, eventAction, eventValue, eventName, ...rest } = props;
   // The first arg is called "eventName" in GA4 , and that's closer to
   // the eventAction value in our piwik values.
   trackCustomGAEvent(eventAction, {
      eventCategory,
      eventName,
      eventValue,
      ...rest,
   });
   trackInPiwik({
      eventCategory,
      eventAction,
      eventName,
      eventValue,
   });
}
