import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import API_2_0 from 'Shared/api_2_0';

export enum IndividualDetailsActionType {
   SET_INDIVIDUAL_DETAILS = 'SET_INDIVIDUAL_DETAILS',
   INDIVIDUAL_DETAILS_ERROR = 'INDIVIDUAL_DETAILS_ERROR',
}

export const getIndividualDetails =
   (userid: number): ThunkAction<any, any, any, Action> =>
   dispatch => {
      const request = new API_2_0(`course_assignments/individualSummaries/${userid}`, {
         method: 'GET',
      });
      return request.send().then(
         (body: any) => {
            dispatch(setIndividualDetails(userid, body.courses));
         },
         (error: any) => {
            dispatch(individualDetailsError(userid, error));
         }
      );
   };

export interface SetIndividualDetailsAction extends Action {
   type: typeof IndividualDetailsActionType.SET_INDIVIDUAL_DETAILS;
   userid: number;
   courses: any[];
}

const setIndividualDetails = (userid: number, courses: any[]): SetIndividualDetailsAction => ({
   type: IndividualDetailsActionType.SET_INDIVIDUAL_DETAILS,
   userid,
   courses,
});

export interface IndividualDetailsErrorAction extends Action {
   type: typeof IndividualDetailsActionType.INDIVIDUAL_DETAILS_ERROR;
   userid: number;
   error: any;
}

const individualDetailsError = (userid: number, error: any): IndividualDetailsErrorAction => ({
   type: IndividualDetailsActionType.INDIVIDUAL_DETAILS_ERROR,
   userid,
   error,
});
